<template>
  <div>
    <div class="inline">
      <div class="col-md-12">
        <KTCodePreview v-bind:title="'Chuyển điểm tiêu dùng'">
          <template v-slot:preview>
            <v-form ref="form" v-model="valid" lazy-validation>
              <div class="row">
                <b-form-group class="col-3">
                  <template>
                    <label>Số điện thoại chuyển điểm:</label>
                    <span class="text-danger">*</span>
                  </template>
                  <Autosuggest
                    :model="$v.form.searchCustomerTransfer.$model"
                    :suggestions="filteredOptionsCustomer"
                    :suggestionName="'phoneNo'"
                    placeholder="số điện thoại"
                    :state="validateState('searchCustomerTransfer')"
                    :limit="10"
                    :disabled="true"
                    @change="onSearchChangeTransfer"
                    @select="onSelectedCustomerTransfer"
                  >
                    <template #custom="{ suggestion }">
                      <div>
                        <div class="d-flex flex-column">
                          <span
                            class="text-primary mb-1"
                            v-if="suggestion.item.phoneNo"
                            >({{ suggestion.item.phoneNo }})</span
                          >
                          <span v-if="suggestion.item.fullName">{{
                            suggestion.item.fullName
                          }}</span>
                          <span v-else>{{ suggestion.item.message }}</span>
                        </div>
                      </div>
                    </template>
                  </Autosuggest>
                  <div v-if="customerTransfer.customerId" class="mt-3">
                    <label>Thông tin khách hàng chuyển điểm:</label>
                    <p>
                      Tên: <strong>{{ customerTransfer.customerName }}</strong>
                    </p>
                    <p>
                      Điểm:
                      <span class="text-danger font-weight-bold">{{
                        customerTransfer.totalPoint
                      }}</span>
                    </p>
                  </div>
                  <b-form-invalid-feedback id="input-live-feedback"
                    >Vui lòng chọn số điện thoại</b-form-invalid-feedback
                  >
                </b-form-group>
                <b-form-group class="col-3">
                  <template>
                    <label>Số điểm:</label>
                  </template>
                  <b-form-input
                    size="sm"
                    type="number"
                    v-model="$v.form.pointTransfer.$model"
                    :disabled="true"
                    :state="validateState('pointTransfer')"
                    required
                    placeholder="Nhập số điểm"
                    class="mb-3"
                  ></b-form-input>
                  <b-form-invalid-feedback id="input-live-feedback"
                    >Vui lòng nhập số điểm</b-form-invalid-feedback
                  >
                </b-form-group>
                <b-form-group class="col-3">
                  <template>
                    <label>Số điện thoại nhận điểm:</label>
                    <span class="text-danger">*</span>
                  </template>
                  <Autosuggest
                    :model="$v.form.searchCustomerReceive.$model"
                    :suggestions="filteredOptionsCustomerReceive"
                    :disabled="true"
                    :suggestionName="'phoneNo'"
                    :state="validateState('searchCustomerReceive')"
                    placeholder="số điện thoại"
                    :limit="10"
                    @change="onSearchChangeReceive"
                    @select="onSelectedCustomerReceive"
                  >
                    <template #custom="{ suggestion }">
                      <div>
                        <div class="d-flex flex-column">
                          <span
                            class="text-primary mb-1"
                            v-if="suggestion.item.phoneNo"
                            >({{ suggestion.item.phoneNo }})</span
                          >
                          <span v-if="suggestion.item.fullName">{{
                            suggestion.item.fullName
                          }}</span>
                          <span v-else>{{ suggestion.item.message }}</span>
                        </div>
                      </div>
                    </template>
                  </Autosuggest>
                  <div v-if="customerReceive.customerId" class="mt-3">
                    <label>Thông tin khách hàng chuyển điểm:</label>
                    <p>
                      Tên: <strong>{{ customerReceive.customerName }}</strong>
                    </p>
                    <p>
                      Điểm:
                      <span class="text-danger font-weight-bold">{{
                        customerReceive.totalPoint
                      }}</span>
                    </p>
                  </div>
                </b-form-group>
                <b-form-group
                  class="col-3"
                  v-if="checkPermission(['POINT_TRANSTER_UPDATE'])"
                >
                  <template>
                    <label>Trạng thái:</label>
                    <span class="text-danger">*</span>
                  </template>
                  <treeselect
                    :options="statusOptions"
                    :multiple="false"
                    placeholder="Chọn trạng thái"
                    noResultsText="Không có kết quả"
                    :match-keys="['label']"
                    openDirection="bottom"
                    :clearable="false"
                    v-model="$v.form.status.$model"
                  >
                    <label
                      slot="option-label"
                      slot-scope="{ node, labelClassName }"
                      :class="labelClassName"
                    >
                      <span :title="node.label"> {{ node.label }}</span>
                    </label>
                  </treeselect>
                </b-form-group>
              </div>
              <div class="row">
                <b-form-group class="col-9">
                  <template>
                    <label>Ghi chú:</label>
                  </template>
                  <b-form-textarea
                    size="sm"
                    id="textarea1"
                    v-model="$v.form.description.$model"
                    :placeholder="'thêm ghi chú'"
                    :disabled="true"
                    :state="validateState('description')"
                    :rows="6"
                    :max-rows="8"
                  ></b-form-textarea>
                  <b-form-invalid-feedback id="input-live-feedback"
                    >Vui lòng nhập ghi chú</b-form-invalid-feedback
                  >
                </b-form-group>
                <b-form-group
                  class="col-3"
                  v-if="form.status === POINT_TRANSFER_STATUS.REJECT"
                >
                  <template>
                    <label>Nhập lý do từ chối:</label>
                  </template>
                  <b-form-textarea
                    size="sm"
                    id="textarea1"
                    v-model="$v.form.reason.$model"
                    :placeholder="'thêm lý do từ chối'"
                    :state="validateState('reason')"
                    :rows="6"
                    :max-rows="8"
                  ></b-form-textarea>
                  <b-form-invalid-feedback id="input-live-feedback"
                    >Vui lòng nhập lí do từ chối</b-form-invalid-feedback
                  >
                </b-form-group>
              </div>
            </v-form>
          </template>
          <template
            v-slot:foot
            v-if="checkPermission(['POINT_TRANSTER_UPDATE'])"
          >
            <b-button
              style="fontweight: 600; width: 70px"
              variant="primary"
              size="sm"
              type="submit"
              @click="updatePoinTransfer"
              >Lưu</b-button
            >
            <router-link :to="'/customer-transfer'">
              <b-button
                style="margin-left: 10px; font-weight: 600; width: 70px"
                variant="secondary"
                size="sm"
                >Hủy</b-button
              >
            </router-link>
          </template>
        </KTCodePreview>
      </div>
    </div>
  </div>
</template>
<style scoped>
.gender-appellation {
  display: flex;
  gap: 4px;
}

.center-label label {
  margin-bottom: 0px;
}

.form-group label {
  font-weight: 500;
}
::v-deep .autosuggest__results ul {
  list-style: none;
  cursor: pointer;
  margin-bottom: 0;
}

::v-deep .autosuggest__results {
  position: absolute;
  background-color: #fff;
  z-index: 1;
  width: 100%;
  border: 1px solid #e0e0e0;
  overflow: scroll;
  max-height: 200px;
  position: absolute;
}

::v-deep .autosuggest__results-container {
  position: relative;
  width: 100%;
  z-index: 10000001;
  font-weight: 500;
  background: white;
}

::v-deep #autosuggest__input {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: calc(1.35em + 1.1rem + 2px);
  padding: 0.55rem 0.75rem;
  font-size: 0.925rem;
  line-height: 1.35;
  border-radius: 0.28rem;
}

::v-deep #autosuggest__input:focus {
  outline: none !important;
  border: 1px solid #92c5f9;
}

::v-deep .autosuggest__results-item:hover {
  background-color: #ddd;
}

::v-deep .autosuggest__results ul li {
  padding: 8px;
}
::v-deep .vue-treeselect__control {
  border-radius: none;
  height: 2.5rem;
}
</style>
<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import ApiService from '@/core/services/api.service';
import { required, minValue } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import {
  CUSTOMER_TYPE,
  CUSTOMER_GENDER,
  CUSTOMER_APPELLATION,
} from '@/utils/constants';
// Import datePicker component
// import datePicker from 'vue-bootstrap-datetimepicker';
// Import date picker css
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import jQuery from 'jquery';
// Using font-awesome 5 icons
import '@fortawesome/fontawesome-free/css/fontawesome.css';
import '@fortawesome/fontawesome-free/css/regular.css';
import '@fortawesome/fontawesome-free/css/solid.css';
import Autosuggest from '@/view/base/auto-sugguest/AutoSuggest.vue';
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import { checkPermission } from '../../../utils/saveDataToLocal';

jQuery.extend(true, jQuery.fn.datetimepicker.defaults, {
  icons: {
    time: 'far fa-clock',
    date: 'far fa-calendar',
    up: 'fas fa-arrow-up',
    down: 'fas fa-arrow-down',
    previous: 'fas fa-chevron-left',
    next: 'fas fa-chevron-right',
    today: 'fas fa-calendar-check',
    clear: 'far fa-trash-alt',
    close: 'far fa-times-circle',
  },
});
import { xoa_dau } from '../../../utils/common';
import { v4 as uuidv4 } from 'uuid';
import { TIME_TRIGGER } from '../../../utils/constants';
import decounce from 'debounce';
import { POINT_TRANSFER_STATUS } from '../../../utils/enum';

export default {
  mixins: [validationMixin],
  data() {
    return {
      POINT_TRANSFER_STATUS,
      statusOptions: [
        { id: 0, label: 'Mới' },
        { id: 1, label: 'Duyệt' },
        { id: 2, label: 'Từ chối' },
      ],
      pointTransferId: null,
      description: '',
      pointTransfer: 0,
      filteredOptionsCustomer: [],
      filteredOptionsCustomerReceive: [],
      listCustomer: [],
      searchCustomerTransfer: '',
      optionsCustomer: [
        {
          data: [],
        },
      ],
      optionsCustomerReceive: [
        {
          data: [],
        },
      ],
      searchCustomerReceive: '',
      inputsearchCustomerTransferProp: {
        id: 'autosuggest__input',
        placeholder: 'SĐT Khách hàng',
        style: 'border-radius:0px!important',
        disabled: false,
      },

      customerTransfer: {
        customerId: null,
        customerMobile: '',
        customerName: '',
        customerLastName: '',
        customerAddress: '',
        customerCity: null,
        customerDistrict: null,
        customerWard: null,
        customerType: 1,
        totalPoint: 0,
      },
      customerReceive: {
        customerId: null,
        customerMobile: '',
        customerName: '',
        customerLastName: '',
        customerAddress: '',
        customerCity: null,
        customerDistrict: null,
        customerWard: null,
        customerType: 1,
        totalPoint: 0,
      },
      valid: true,
      form: {
        searchCustomerReceive: '',
        pointTransfer: 0,
        searchCustomerTransfer: '',
        description: '',
        status: null,
        reason: null,
      },
      isNew: true,
      radios: 'male',
      selectedType: null,
      listType: CUSTOMER_TYPE,
      note: '',
      birthday: '',
      address: '',
      selectedCity: null,
      listCity: [],
      selectedDistrict: null,
      listDistrict: [],
      selectWard: null,
      listWard: [],
      listGender: CUSTOMER_GENDER,
      listAppellation: CUSTOMER_APPELLATION,
      selectedEmployee: null,
      listEmployee: [],
      lastName: '',
      filteredOptionEmployee: [],
      optionEmployee: [],
      searchEmployee: '',
      email: '',
      showErrorGender: false,
      idempotenceKey: null,
    };
  },
  validations: {
    form: {
      searchCustomerReceive: { required },
      pointTransfer: { required, minValue: minValue(1) },
      searchCustomerTransfer: { required },
      description: { required },
      status: { required },
      reason: {},
    },
  },
  components: {
    KTCodePreview,
    Treeselect,
    Autosuggest,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Danh sách phiếu chuyển điểm', route: '/customer-transfer' },
      { title: 'Tạo phiếu chuyển điểm' },
    ]);
  },
  created() {
    this.pointTransferId = this.$route.query.id;
    this.fetchData();
  },
  methods: {
    checkPermission,
    fetchData: function() {
      this.onLoading = true;
      this.page = this.$route.query.page || 1;

      const params = {
        id: this.pointTransferId,
        limit: 10,
        page: this.page,
      };
      ApiService.query('customer/transfer-point', {
        params: params,
      }).then(({ data }) => {
        const item = data.data.dataset[0];
        const {
          infoCustomerTransfer,
          infoCustomerReceive,
          point,
          description,
          status,
          reason,
        } = item;
        this.customerTransfer.customerId = infoCustomerTransfer.id;
        this.customerTransfer.customerMobile = infoCustomerTransfer.phoneNo;
        this.customerReceive.customerId = infoCustomerReceive.id;
        this.customerReceive.customerMobile = infoCustomerReceive.phoneNo;
        this.getCustomerInfoById(infoCustomerTransfer.id, 'TRANSFER');
        this.getCustomerInfoById(infoCustomerReceive.id, 'RECEIVE');
        this.form.searchCustomerTransfer = infoCustomerTransfer.phoneNo;
        this.form.searchCustomerReceive = infoCustomerReceive.phoneNo;
        this.form.pointTransfer = +point;
        this.form.description = description;
        this.form.status = status;
        this.form.reason = reason;
        this.onLoading = false;
      });
    },
    onSelectedCustomerTransfer(option) {
      if (option.item.id) {
        this.customerTransfer.customerId = option.item.id;
        this.customerTransfer.customerMobile = option.item.phoneNo;
        this.customerTransfer.customerAppellation = option.item.appellation;
        this.getCustomerInfoById(option.item.id, 'TRANSFER');
        // this.getTransactionByCustomerId(option.item.id);
      } else {
        this.customerTransfer.customerId = '';
        this.customerTransfer.customerMobile = option.item.phoneNo;
        this.customerTransfer.customerName = '';
        this.customerTransfer.totalPoint = 0;
      }
    },
    onSelectedCustomerReceive(option) {
      if (option.item.id) {
        this.customerReceive.customerId = option.item.id;
        this.customerReceive.customerMobile = option.item.phoneNo;
        this.customerReceive.customerAppellation = option.item.appellation;
        this.getCustomerInfoById(option.item.id, 'RECEIVE');
        // this.getTransactionByCustomerId(option.item.id);
      } else {
        this.customerReceive.customerId = '';
        this.customerReceive.customerMobile = option.item.phoneNo;
        this.customerReceive.customerName = '';
        this.customerReceive.totalPoint = 0;
      }
    },
    getCustomerInfoById(customerId, method) {
      if (customerId) {
        ApiService.get(`customer/${customerId}`).then((response) => {
          const customerInfo = response.data.data;
          if (method === 'TRANSFER') {
            this.customerTransfer.customerName = customerInfo.fullName;
            this.customerTransfer.customerCity = customerInfo.city;
            this.customerTransfer.customerWard = customerInfo.ward;
            this.customerTransfer.customerDistrict = customerInfo.district;
            this.customerTransfer.customerAddress = customerInfo.address;
            this.customerTransfer.customerType = customerInfo.type
              ? customerInfo.type
              : '';
            this.customerTransfer.totalPoint = customerInfo.totalPoint;
            const totalPoint = Number(customerInfo.totalPoint) || 0;
            this.pointText = ` <b>(Tổng điểm:  ${totalPoint})</b>`;
            this.customerTransfer.customerLastName = customerInfo.lastName;
          } else {
            this.customerReceive.customerName = customerInfo.fullName;
            this.customerReceive.customerCity = customerInfo.city;
            this.customerReceive.customerWard = customerInfo.ward;
            this.customerReceive.customerDistrict = customerInfo.district;
            this.customerReceive.customerAddress = customerInfo.address;
            this.customerReceive.customerType = customerInfo.type
              ? customerInfo.type
              : '';
            this.customerReceive.totalPoint = customerInfo.totalPoint;
            const totalPoint = Number(customerInfo.totalPoint) || 0;
            this.pointText = ` <b>(Tổng điểm:  ${totalPoint})</b>`;
            this.customerReceive.customerLastName = customerInfo.lastName;
          }
        });
      }
    },
    getListCustomer() {
      const text = this.$v.form.searchCustomerTransfer.$model
        ? this.$v.form.searchCustomerTransfer.$model.trim()
        : null;
      // const text = this.searchCustomerTransfer
      //   ? this.searchCustomerTransfer.trim()
      //   : null;
      if (!text) {
        return;
      }
      ApiService.get(`customer/get-by-phone?phoneNumber=${text}`).then(
        (data) => {
          this.optionsCustomer = data.data.data;
          if (this.optionsCustomer.length > 0) {
            this.filteredOptionsCustomer = [...this.optionsCustomer];
          } else {
            this.filteredOptionsCustomer = [
              { message: 'SĐT không có trong hệ thống', phoneNo: text },
            ];
          }
        },
      );
    },
    getListCustomerReceive() {
      const text = this.$v.form.searchCustomerReceive.$model
        ? this.$v.form.searchCustomerReceive.$model.trim()
        : null;
      if (!text) {
        return;
      }
      ApiService.get(`customer/get-by-phone?phoneNumber=${text}`).then(
        (data) => {
          this.optionsCustomerReceive = data.data.data;
          if (this.optionsCustomerReceive.length > 0) {
            this.filteredOptionsCustomerReceive = [
              ...this.optionsCustomerReceive,
            ];
          } else {
            this.filteredOptionsCustomerReceive = [
              { message: 'SĐT không có trong hệ thống', phoneNo: text },
            ];
          }
        },
      );
    },
    onSearchChangeTransfer(searchQuery) {
      if (!searchQuery) {
        searchQuery = '';
      }
      this.$v.form.searchCustomerTransfer.$model = searchQuery;
      this.optionsCustomer = [];
      this.filteredOptionsCustomer = [...this.optionsCustomer];
      this.debounceInput();
    },
    onSearchChangeReceive(searchQuery) {
      if (!searchQuery) {
        searchQuery = '';
      }
      this.$v.form.searchCustomerReceive.$model = searchQuery;
      this.optionsCustomerReceive = [];
      this.filteredOptionsCustomer = [...this.optionsCustomerReceive];
      this.debounceInputReceive();
    },
    debounceInput: decounce(function() {
      this.getListCustomer();
    }, TIME_TRIGGER),
    debounceInputReceive: decounce(function() {
      this.getListCustomerReceive();
    }, TIME_TRIGGER),
    onInputChangCustomer(text) {
      if (!text) {
        text = '';
      }
      this.searchCustomerTransfer = text;
      this.optionsCustomer[0].data = [];
      this.filteredOptionsCustomer = [
        {
          data: this.optionsCustomer[0].data,
        },
      ];
      /* Full control over filtering. Maybe fetch from API?! Up to you!!! */
      this.debounceInput();
    },
    format(value) {
      this.birthday = value;
      return value;
    },
    validateState(name) {
      if (!this.isNew) {
        const { $dirty, $error } = this.$v.form[name];
        return $dirty ? !$error : null;
      }
    },
    updatePoinTransfer: async function() {
      this.isNew = false;
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      if (+this.form.point < 1) {
        return;
      }
      const data = {
        id: this.pointTransferId,
        status: this.form.status,
      };
      if (this.form.status === POINT_TRANSFER_STATUS.REJECT) {
        data['reason'] = this.form.reason;
      }
      ApiService.put('customer/transfer-point', data)
        .then(({ data }) => {
          if (data.status === 1) {
            this.makeToastSuccess(data.message);
            this.$router.push({
              path: '/customer-transfer',
            });
          } else {
            this.makeToastFaile(data.message);
          }
          this.idempotenceKey = uuidv4();
        })
        .catch((error) => {
          console.log('error: ', error);
          this.makeToastFaile(error.response.data.message);
        });
    },
    makeToastSuccess(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'success',
        solid: true,
      });
    },
    makeToastFaile(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'danger',
        solid: true,
      });
    },
    fetchEmployee: async function() {
      this.optionEmployee = [];
      ApiService.setHeader();
      ApiService.get('/employees/get-list-filter').then((response) => {
        const employees = response.data.data;
        employees.map((element) => {
          let suggestionName = element.name;
          if (element.code) {
            suggestionName += ' ( ' + element.code + ')';
          }
          let nameNoneSign = xoa_dau(element.name);
          let store = {
            id: element.id,
            name: element.name,
            code: element.code,
            suggestionName: suggestionName,
            nameNoneSign: nameNoneSign,
          };
          this.optionEmployee.push(store);
        });
        this.filteredOptionEmployee = [...this.optionEmployee];
      });
    },
    fetchCity() {
      ApiService.setHeader();
      ApiService.get('city/').then((data) => {
        this.listCity = data.data.data;
      });
    },
    getDistrict() {
      this.selectedDistrict = null;
      this.selectWard = null;
      ApiService.setHeader();
      ApiService.get(`city/${this.selectedCity}/district`).then((data) => {
        this.listDistrict = data.data.data;
      });
    },
    getWard() {
      this.selectWard = null;
      ApiService.setHeader();
      ApiService.get(`city/${this.selectedDistrict}/ward`).then((data) => {
        this.listWard = data.data.data;
      });
    },
    onContext(ctx) {
      if (ctx.selectedYMD == '') {
        this.birthday = '';
      } else {
        this.birthday = ctx.selectedYMD;
      }
    },
    onSelectedEmployee(option) {
      this.searchEmployee = option.item.name;
      this.selectedEmployee = option.item.id;
    },
    onInputChangeEmployee(text) {
      if (!text) {
        text = '';
      }
      this.searchEmployee = text;

      /* Full control over filtering. Maybe fetch from API?! Up to you!!! */
      const filteredData = this.optionEmployee
        .filter((item) => {
          return (
            item.nameNoneSign.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            item.name.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            item.code.toLowerCase().indexOf(text.toLowerCase()) > -1
          );
        })
        .slice(0, this.limit);

      this.filteredOptionEmployee = [...filteredData];
    },
  },
};
</script>
